.container-404{
    background-image: url("./not-found-background.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

.page-not-found-back-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
    color: #9e9e9e;
    /* width: 100%;
    height: 100%; */
    margin: 30px;
}

.page-not-found-label{
    font-family: poppins;
    font-weight: bold;
    font-size: 1.5em;
    margin: 5px;
}

.page-not-found-btn{
    cursor: pointer;
    text-align: center;
    font-size: 18px;
    font-weight: 250;
    font-family: poppins;
    width: auto;
    height: auto;
    background-color: #e0e0e0;
    color: black;
    border-radius: 10px;
    border: #9e9e9e solid;
}

.page-not-found-btn:hover{
    background-color: black;
    color: white;
    border: black solid;
}