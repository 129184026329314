.countryDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
}

.flag-container {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 30px;
  padding: 30px;
  border: 20px;
}

.flag-info {
  width: 100%;
  height: 100%;
  border: #143959 solid;
}

.details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.country-info {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  text-align: left;
  width: 100%;
  height: 100%;
}

.back-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.back{
    width: 100%;
    height: 100%;
}

.back-btn-info {
  background-color: #143959;
  color: #eeeeee;
  font-family: poppins;
  font-size: 15px;
  font-weight: bold;
  border: #eeeeee solid;
  border-radius: 5px;
  width: 100px;
  height: 40px;
  cursor: pointer;
}

.back-btn-info:hover {
  background-color: #eeeeee;
  color: #143959;
  border: #143959 solid;
}

.countries-info-labels {
  font-family: poppins;
  font-weight: bold;
  color: #143959;
}

.countries-info-content {
  font-family: poppins;
  font-weight: bold;
  color: #616161;
}

.activities-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.activities-label-title{
  width: 100%;
}

.activities-label {
  font-family: poppins;
  font-weight: bold;
  color: #143959;
  width: 100%;
}

.activities-info-container{
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 15px;
  padding: 15px;
  color: #616161;
  background: rgba( 20, 57, 89, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 10.5px );
  -webkit-backdrop-filter: blur( 10.5px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.activities-info-container:hover{
  transform: translateY(-15px);
}

.noActivity {
  font-family: poppins;
  font-weight: bold;
  color: #143959;
}

.actInfoLabel{
  color: #616161;
  font-family: poppins;
  font-weight: bold;
  list-style: none;
}

.actInfoLabel-1{
  color: #616161;
  font-family: poppins;
  font-size: 18px;
  font-weight: bold;
  list-style: none;
}

.countryDetails-dark{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #28364e;
}

.flag-info-dark{
  width: 100%;
  height: 100%;
  border: #556ff7 solid;
}

.countries-info-labels-dark{
  font-family: poppins;
  font-weight: bold;
  color: #556ff7;
}

.countries-info-content-dark{
  font-family: poppins;
  font-weight: bold;
  color: white;
}

.activities-label-dark{
  font-family: poppins;
  font-weight: bold;
  color: #556ff7;
  width: 100%;
}

.activities-info-container-dark{
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 15px;
  padding: 15px;
  color: white;
  background-color: #0a0d14;
  border-radius: 10px;
  border: 1px solid #556ff7;
}

.activities-info-container-dark:hover{
  transform: translateY(-15px);
}

.actInfoLabel-dark{
  color: white;
  font-family: poppins;
  font-weight: bold;
  list-style: none;
}

.actInfoLabel-1-dark{
  color: white;
  font-family: poppins;
  font-size: 18px;
  font-weight: bold;
  list-style: none;
}

.back-btn-info-dark{
  background-color: #556ff7;
  color: white;
  font-family: poppins;
  font-size: 15px;
  font-weight: bold;
  border: #556ff7 solid;
  border-radius: 5px;
  width: 100px;
  height: 40px;
  cursor: pointer;
}

.back-btn-info-dark:hover {
  background-color: white;
  color: #556ff7;
}

.noActivity-dark{
  font-family: poppins;
  font-weight: bold;
  color: #556ff7;
}

@media (max-width: 500px) {
  .countryDetails{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .details{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .flag-container{
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin: 10px 30px 0px 30px;
    width: 90%;
  }
  .country-info{
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    text-align: left;
    width: fit-content;
    height: fit-content;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-size: 12px;
  }
  .countries-info-labels{
    font-family: poppins;
    font-weight: bold;
    color: #143959;
    margin: 4px 4px 4px 4px;
  }
  .countries-info-content{
    font-family: poppins;
    font-weight: bold;
    color: #616161;
    margin: 4px 4px 4px 4px;
  }
  .activities-label{
    font-family: poppins;
    font-weight: bold;
    font-size: 15px;
    color: #143959;
    width: 100%;
  }
  .noActivity{
    font-family: poppins;
    font-weight: bold;
    font-size: 15px;
    color: #143959;
  }
  .back-btn-info {
    background-color: #143959;
    color: #eeeeee;
    font-family: poppins;
    font-size: 12px;
    font-weight: bold;
    border: #eeeeee solid;
    border-radius: 5px;
    width: 70px;
    height: 37px;
    cursor: pointer;
  }
  
  .back-btn-info:hover {
    background-color: #eeeeee;
    color: #143959;
    border: #143959 solid;
  }
}

