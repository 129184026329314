.dark-mode-button{
    padding: 0px;
    margin: 0px;
    border: 0px;
    cursor: pointer;
    background-color: transparent;
}

.dark-mode-img{
    width: 50px;
    height: 50px;
}

@media (max-width: 500px) {
    .dark-mode-button{
        padding: 0px;
        margin: 0px;
        border: 0px;
        cursor: pointer;
        background-color: transparent;
    }
    
    .dark-mode-img{
        width: 25px;
        height: 25px;
    }
}