.activities-linkTo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: url("./fondo.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
  color: white;
  font-family: poppins;
  font-weight: bold;
  font-size: 2.5rem;
}

.activities-options-navlink {
  margin: 10px;
}

.touristAct-btn {
  background-color: #d4a373;
  border: 1px solid #bc6c25;
  color: #5e3023;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.touristAct-btn:hover {
  background-color: #bc6c25;
  color: #ffffff;
}

@media (max-width: 500px) {
  .activities-title {
    font-size: 1.8rem;
  }
}
