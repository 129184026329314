.landing {
  background: url("./fondo-landing-page.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding-left: 20px;
  color: white;
}

.title-landing {
  color: white;
  font-size: 1em;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  margin-bottom: 20px;
}

.title-landing-h{
  margin: 10px;
}

.linkTo {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  width: 50%;
}

.landing-navlink {
  text-decoration: none;
}

.landing-btn {
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 250;
  font-family: Arial, Helvetica, sans-serif;
  width: auto;
  height: 50px;
  background-color: rgb(75, 179, 240);
  color: white;
  border-radius: 10px;
  border: white solid;
}

.language-switch {
  display: flex;
}

.landing-btn:hover {
  background-color: white;
  color: rgb(75, 179, 240);
  border: rgb(75, 179, 240) solid;
  font-weight: 300;
}
