.spinner,.spinner:after{
    width: 50px;
    height: 50px;
    border-radius: 50%;
 }
.spinner {
    background-color: transparent;
    border-top: 5px solid rgb(66,139,202);
    border-right: 5px solid rgb(66,139,202);
    border-bottom: 5px solid rgb(66,139,202);
    border-left: 5px solid rgba(66,139,202,.2);
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-duration: .8s;
    animation-name: spinner-loading
 }
@keyframes spinner-loading{
  0% {
      transform: rotate(0deg)
  } to {
      transform: rotate(1turn)
  }
}